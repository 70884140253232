import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d46c44a4"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-sampling" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-6 column-header" };
const _hoisted_4 = { class: "col-6 column-value" };
const _hoisted_5 = { class: "row" };
const _hoisted_6 = { class: "col-6 column-header" };
const _hoisted_7 = { class: "col-6 column-value" };
const _hoisted_8 = { class: "row" };
const _hoisted_9 = { class: "col-6 column-header" };
const _hoisted_10 = { class: "col-6 column-value" };
const _hoisted_11 = { class: "row" };
const _hoisted_12 = { class: "col-6 column-header" };
const _hoisted_13 = { class: "col-6 column-value" };
const _hoisted_14 = { class: "small" };
const _hoisted_15 = { class: "small" };
const _hoisted_16 = { class: "row" };
const _hoisted_17 = { class: "col-6 column-header" };
const _hoisted_18 = { class: "col-6 column-value" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LocalisedField = _resolveComponent("LocalisedField");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate(`form-fields.${_ctx.domainTranslationPrefix("before")}`)), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getTranslation(_ctx.model?.sampleDrawingBeforeUnloading)), 1)
        ]),
        (_ctx.model?.sampleDrawingBeforeUnloading)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("form-fields.drawing-by")), 1),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.showDrawingBy()), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translate("form-fields.drawing-from")), 1),
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.showDrawingFrom()), 1)
                ]),
                _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.translate(`form-fields.lab-testing.${_ctx.domainTranslationPrefix("label")}`)), 1),
                    _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.getTranslation(_ctx.model?.sampleForLaboratoryTestingToReleaseUnloading)), 1),
                        (_ctx.model?.sampleForLaboratoryTestingToReleaseUnloading)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.translate("form-fields.lab-testing.description")), 1),
                                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.getSingularOrPluralInfo(_ctx.model?.minimumWaitingTimeForTestResults, "form-fields.lab-testing.hour", "form-fields.lab-testing.hours")), 1)
                            ], 64))
                            : _createCommentVNode("", true)
                    ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.translate("form-fields.retained.label")), 1),
                    _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.showRetainedSample()), 1)
                ]),
                _createVNode(_component_LocalisedField, {
                    modelValue: _ctx.model?.additionalInformation
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("form-fields.additional-information.label")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"])
            ], 64))
            : _createCommentVNode("", true)
    ]));
}
