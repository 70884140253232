import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b97a9f42"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-online-check-in" };
const _hoisted_2 = { class: "section" };
const _hoisted_3 = { class: "row section" };
const _hoisted_4 = { class: "col-6 column-header" };
const _hoisted_5 = { class: "col-6 column-description" };
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { class: "row section" };
const _hoisted_8 = { class: "col-6 column-header" };
const _hoisted_9 = { class: "col-6 column-description" };
const _hoisted_10 = { class: "section" };
const _hoisted_11 = { class: "row section" };
const _hoisted_12 = { class: "col-6 column-header" };
const _hoisted_13 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h5", null, _toDisplayString(_ctx.translate("name")), 1),
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("online-check-in-requirement")), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.requirementStatus), 1)
            ]),
            (_ctx.requirementAvailable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translate("check-in-latest")), 1),
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.checkInLatestInfo), 1)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_DisplayAttachments, {
                            attachments: _ctx.modelValue?.onlineCheckInProcedures,
                            title: _ctx.translate('online-check-in-procedure')
                        }, null, 8, ["attachments", "title"])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.translate("languages-procedure-title")), 1),
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.languagesInfo), 1)
                    ])
                ]))
                : _createCommentVNode("", true)
        ])
    ]));
}
