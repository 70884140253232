import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-906a879e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-special-product-requirements" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-6 column-header" };
const _hoisted_4 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_5 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_6 = { class: "row" };
const _hoisted_7 = { class: "col-6 column-header" };
const _hoisted_8 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_9 = {
    key: 1,
    class: "col-6 column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LocalisedField = _resolveComponent("LocalisedField");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("tank-material")), 1),
            (_ctx.model?.tankMaterials?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.tankMaterials, (tag, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                            key: tag + index,
                            class: "tag"
                        }, _toDisplayString(_ctx.getValue(tag, "TankMaterial")), 1));
                    }), 128))
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, "-"))
        ]),
        _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("dome-cover-gasket-material")), 1),
            (_ctx.model?.domeValveGasketMaterials?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.domeValveGasketMaterials, (tag, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                            key: tag + index,
                            class: "tag"
                        }, _toDisplayString(_ctx.getValue(tag, "DomeValveGasketMaterial")), 1));
                    }), 128))
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_9, "-"))
        ]),
        _createVNode(_component_LocalisedField, {
            modelValue: _ctx.model?.additionalInformation
        }, {
            header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("form-fields.additional-information.label")), 1)
            ]),
            _: 1
        }, 8, ["modelValue"])
    ]));
}
