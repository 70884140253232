import { GeneralInformationModuleId } from "@/app/modules/general-information/general-information.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { createModule } from "@/di/module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import EditProcessesComponent from "@/app/sub-modules/processes/components/edit-processes.component.vue";
import DisplayProcessesComponent from "@/app/sub-modules/processes/components/display-processes.component.vue";
import DisplayProcessesViewComponent from "@/app/sub-modules/processes/components/display-processes-view.component.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { ProcessesSubModuleId } from "@/app/sub-modules/processes/processes.constants";
import { LoadingPointAssignedProductsDomainId, UnloadingPointAssignedProductsDomainId, } from "@/app/domains/assigned-products/assigned-products.constants";
import { InstructionsModuleId } from "@/app/modules/instructions/instructions.module";
export const ProcessesSubModule = (domainId, options) => {
    const id = ProcessesSubModuleId;
    const moduleId = getModuleIdFromDomainId(domainId);
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditProcessesComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayProcessesComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayProcessesViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "apps",
                publicInformation: options?.publicInformation,
                editGuards: options?.editGuards,
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.processes" }],
    });
};
const getModuleIdFromDomainId = (domainId) => {
    if (domainId === LoadingPointAssignedProductsDomainId || domainId === UnloadingPointAssignedProductsDomainId) {
        return InstructionsModuleId;
    }
    return GeneralInformationModuleId;
};
