import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0f8cf096"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-temperature-requirements" };
const _hoisted_2 = {
    key: 0,
    class: "column-description mb-0"
};
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col-6 column-header" };
const _hoisted_6 = {
    key: 0,
    class: "col-6 column-description d-flex align-items-center"
};
const _hoisted_7 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_8 = { class: "row" };
const _hoisted_9 = { class: "col-6 column-header" };
const _hoisted_10 = {
    key: 0,
    class: "d-flex col-6 flex-column"
};
const _hoisted_11 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_12 = { class: "row" };
const _hoisted_13 = { class: "col-6 column-header" };
const _hoisted_14 = {
    key: 0,
    class: "d-flex col-6 flex-column"
};
const _hoisted_15 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_16 = { class: "row" };
const _hoisted_17 = { class: "col-6 column-header" };
const _hoisted_18 = {
    key: 0,
    class: "d-flex col-6 flex-column"
};
const _hoisted_19 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_20 = { class: "row" };
const _hoisted_21 = { class: "col-6 column-header" };
const _hoisted_22 = {
    key: 0,
    class: "col-6 column-description d-flex align-items-center"
};
const _hoisted_23 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_24 = { class: "row" };
const _hoisted_25 = { class: "col-6 column-header" };
const _hoisted_26 = {
    key: 0,
    class: "d-flex col-6 flex-column"
};
const _hoisted_27 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_28 = { class: "row" };
const _hoisted_29 = { class: "col-6 column-header" };
const _hoisted_30 = {
    key: 0,
    class: "col-6 column-description d-flex align-items-center"
};
const _hoisted_31 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_32 = { class: "row" };
const _hoisted_33 = { class: "col-6 column-header" };
const _hoisted_34 = {
    key: 0,
    class: "col-6 column-description allowed-heating-medium"
};
const _hoisted_35 = {
    key: 0,
    class: "mb-2"
};
const _hoisted_36 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_37 = { class: "row" };
const _hoisted_38 = { class: "col-6 column-header" };
const _hoisted_39 = {
    key: 0,
    class: "col-6 column-description d-flex align-items-center"
};
const _hoisted_40 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_41 = { class: "row" };
const _hoisted_42 = { class: "col-6 column-header" };
const _hoisted_43 = {
    key: 0,
    class: "d-flex col-6 flex-column"
};
const _hoisted_44 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_45 = { class: "row" };
const _hoisted_46 = { class: "col-6 column-header" };
const _hoisted_47 = {
    key: 0,
    class: "d-flex col-6 flex-column"
};
const _hoisted_48 = {
    key: 1,
    class: "col-6 column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TemperatureDateRangeDisplayComponent = _resolveComponent("TemperatureDateRangeDisplayComponent");
    const _component_LocalisedField = _resolveComponent("LocalisedField");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.noSpecialRequirements)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.translate("no-special-requirements")), 1))
            : (!_ctx.noSpecialRequirements && _ctx.hasData)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate("maximum-contact-temperature")), 1),
                        (_ctx.model.maxContactTemperature)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.model.maxContactTemperature) + " " + _toDisplayString(_ctx.getValue(_ctx.model.maxContactTemperatureUnit, "TemperatureUnit")), 1))
                            : (_openBlock(), _createElementBlock("div", _hoisted_7, "-"))
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translate("tank-temperature-loading")), 1),
                        (!_ctx.checkIfEmpty('tankTemperatureAtTheTimeOfLoading'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _createVNode(_component_TemperatureDateRangeDisplayComponent, {
                                    modelValue: _ctx.model.tankTemperatureAtTheTimeOfLoading,
                                    tempUnit: _ctx.model.tankTemperatureAtTheTimeOfLoadingTemperatureUnit
                                }, null, 8, ["modelValue", "tempUnit"])
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_11, "-"))
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.translate("loading-temperature")), 1),
                        (!_ctx.checkIfEmpty('loadingTemperature'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                _createVNode(_component_TemperatureDateRangeDisplayComponent, {
                                    modelValue: _ctx.model.loadingTemperature,
                                    tempUnit: _ctx.model.loadingTemperatureUnit
                                }, null, 8, ["modelValue", "tempUnit"])
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_15, "-"))
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.translate("transport-temperature")), 1),
                        (!_ctx.checkIfEmpty('transportTemperature'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                _createVNode(_component_TemperatureDateRangeDisplayComponent, {
                                    modelValue: _ctx.model.transportTemperature,
                                    tempUnit: _ctx.model.transportTemperatureUnit
                                }, null, 8, ["modelValue", "tempUnit"])
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_19, "-"))
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.translate("heating-during-transport")), 1),
                        (!!_ctx.model.heatingDuringTransport)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.getValue(_ctx.model.heatingDuringTransport, "HeatingDuringTransport")), 1))
                            : (_openBlock(), _createElementBlock("div", _hoisted_23, "-"))
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.translate("heating-settings-during-transport")), 1),
                        (!_ctx.checkIfEmpty('heatingSettingsDuringTransport'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                _createVNode(_component_TemperatureDateRangeDisplayComponent, {
                                    modelValue: _ctx.model.heatingSettingsDuringTransport,
                                    tempUnit: _ctx.model.heatingSettingsDuringTransportTemperatureUnit
                                }, null, 8, ["modelValue", "tempUnit"])
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_27, "-"))
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.translate("heating-type")), 1),
                        (!_ctx.checkIfEmpty('heatingType'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_30, _toDisplayString(_ctx.getValue(_ctx.model.heatingType, "HeatingType")), 1))
                            : (_openBlock(), _createElementBlock("div", _hoisted_31, "-"))
                    ]),
                    _createElementVNode("div", _hoisted_32, [
                        _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.translate("max-heating-medium-temperature")), 1),
                        (_ctx.isAnyHeatingMediumEnabled())
                            ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.heatingMediumTemperatureRestrictions, (tempRestriction, index) => {
                                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                                        (tempRestriction?.isEnabled)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_35, _toDisplayString(_ctx.getValue(tempRestriction?.heatingMedium, "HeatingMedium")) + ": " + _toDisplayString(tempRestriction?.maxHeatingMediumTemperature) + " " + _toDisplayString(_ctx.getValue(tempRestriction?.maxHeatingMediumTemperatureUnit, "TemperatureUnit")), 1))
                                            : _createCommentVNode("", true)
                                    ]));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_36, "-"))
                    ]),
                    _createElementVNode("div", _hoisted_37, [
                        _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.translate("cooling-during-transport")), 1),
                        (!!_ctx.model.coolingDuringTransport)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_39, _toDisplayString(_ctx.getValue(_ctx.model.coolingDuringTransport, "CoolingDuringTransport")), 1))
                            : (_openBlock(), _createElementBlock("div", _hoisted_40, "-"))
                    ]),
                    _createElementVNode("div", _hoisted_41, [
                        _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.translate("cooling-settings-during-transport")), 1),
                        (!_ctx.checkIfEmpty('coolingSettingsDuringTransport'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                                _createVNode(_component_TemperatureDateRangeDisplayComponent, {
                                    modelValue: _ctx.model.coolingSettingsDuringTransport,
                                    tempUnit: _ctx.model.coolingSettingsDuringTransportTemperatureUnit
                                }, null, 8, ["modelValue", "tempUnit"])
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_44, "-"))
                    ]),
                    _createElementVNode("div", _hoisted_45, [
                        _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.translate("unloading-temperature")), 1),
                        (!_ctx.checkIfEmpty('unloadingTemperature'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                                _createVNode(_component_TemperatureDateRangeDisplayComponent, {
                                    modelValue: _ctx.model.unloadingTemperature,
                                    tempUnit: _ctx.model.unloadingTemperatureUnit
                                }, null, 8, ["modelValue", "tempUnit"])
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_48, "-"))
                    ]),
                    _createVNode(_component_LocalisedField, {
                        modelValue: _ctx.model?.additionalTemperatureRequirements
                    }, {
                        header: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("additional-temperature-requirements")), 1)
                        ]),
                        _: 1
                    }, 8, ["modelValue"])
                ]))
                : _createCommentVNode("", true)
    ]));
}
