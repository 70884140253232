import { DataLocalisationModule } from "@/app/data-localisation/data-localisation.module";
import { SelectedCompanyFilterService } from "@/app/domains/public-data/companies/services/selected-company-filter.service";
import { LoadingPointsModule } from "@/app/domains/loading-points/loading-points.module";
import CreateSiteFormComponent from "@/app/domains/sites/components/create-site-form.component.vue";
import SiteContextMenuHeaderComponent from "@/app/domains/sites/components/site-context-menu-header.component.vue";
import SitesTableComponent from "@/app/domains/sites/components/sites-table.component.vue";
import { SelectedSiteFilterService } from "@/app/domains/sites/services/selected-site-filter.service";
import { SiteDomainId } from "@/app/domains/sites/sites.constants";
import { UnloadingPointsModule } from "@/app/domains/unloading-points/unloading-points.module";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { CheckInModule } from "@/app/modules/check-in/check-in.module";
import { FacilitiesModule } from "@/app/modules/facilities/facilities.module";
import { GatesModule } from "@/app/modules/gates/gates.module";
import { GeneralInformationModule } from "@/app/modules/general-information/general-information.module";
import { SafetyAndSecurityModule } from "@/app/modules/safety-and-security/safety-and-security.module";
import { AccessRestrictionsSubModule } from "@/app/sub-modules/access-restrictions/access-restrictions.module";
import { SiteAdditionalInformationSubModule } from "@/app/sub-modules/additional-information/site/site-additional-information.module";
import { ParkingSpaceBasicSubModule } from "@/app/sub-modules/parking-space-basic/parking-space-basic.module";
import { AlarmsAndEmergencySubModule } from "@/app/sub-modules/alarms-and-emargency/alarms-and-emargency.module";
import { ProcessesSubModule } from "@/app/sub-modules/processes/processes.module";
import { CompanyInformationSubModule } from "@/app/sub-modules/company-information/company-information.module";
import { GatesSubModule } from "@/app/sub-modules/gates/gates.module";
import { GeneralSafetyRulesSubModule } from "@/app/sub-modules/general-safety-rules/general-safety-rules.module";
import { InfrastructuresNearbySubModule } from "@/app/sub-modules/infrastructures-nearby/infrastructures-nearby.module";
import { VehicleAndPPESubModule } from "@/app/sub-modules/vehicle-and-ppe/vehicle-and-ppe.module";
import { OpeningHoursSubModule } from "@/app/sub-modules/opening-hours/opening-hours.module";
import { PostalAddressSubModule } from "@/app/sub-modules/postal-address/postal-address.module";
import { SafetyTestSubModule } from "@/app/sub-modules/safety-test/safety-test.module";
import { SiteIncidentManagementSubModule } from "@/app/sub-modules/site-incident-management/site-incident-management.module";
import { SiteNavigationSubModule } from "@/app/sub-modules/site-navigation/site-navigation.module";
import { TrafficRelatedRulesSubModule } from "@/app/sub-modules/traffic-related-rules/traffic-related-rules.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import { WeighingBridgesSubModule } from "@/app/sub-modules/weighing-bridges/weighing-bridges.module";
import { SafetyAndSecurityModuleId } from "@/app/modules/safety-and-security/safety-and-security.module";
import { CheckInSubModule } from "@/app/sub-modules/check-in/check-in.module";
import { BuildingsModule } from "@/app/domains/buildings/buildings.module";
const subModuleOptions = {
    publicInformation: true,
};
export const SitesModule = createModule({
    components: [SiteContextMenuHeaderComponent, SitesTableComponent, CreateSiteFormComponent],
    imports: [
        DomainModule({
            id: SiteDomainId,
            icon: "holiday_village",
            detailsIcon: "location_city",
            domainTranslationPrefix: "site",
            baseUrl: "sites",
            forBusinessPartner: true,
            lists: [
                {
                    id: "company",
                    default: true,
                    filter: SelectedCompanyFilterService,
                },
            ],
            components: {
                contextMenuHeader: SiteContextMenuHeaderComponent,
                table: SitesTableComponent,
                createForm: CreateSiteFormComponent,
            },
        }),
        GeneralInformationModule(SiteDomainId),
        CompanyInformationSubModule(SiteDomainId, subModuleOptions),
        PostalAddressSubModule(SiteDomainId, subModuleOptions),
        SiteNavigationSubModule(SiteDomainId, subModuleOptions),
        AccessRestrictionsSubModule(SiteDomainId, subModuleOptions),
        OpeningHoursSubModule(SiteDomainId, subModuleOptions),
        SiteAdditionalInformationSubModule(SiteDomainId, subModuleOptions),
        ProcessesSubModule(SiteDomainId, subModuleOptions),
        //
        FacilitiesModule(SiteDomainId),
        WeighingBridgesSubModule(SiteDomainId),
        ParkingSpaceBasicSubModule(SiteDomainId),
        InfrastructuresNearbySubModule(SiteDomainId),
        //
        CheckInModule(SiteDomainId),
        CheckInSubModule(SiteDomainId),
        //
        GatesModule(SiteDomainId),
        GatesSubModule(SiteDomainId),
        //
        SafetyAndSecurityModule(SiteDomainId),
        GeneralSafetyRulesSubModule(SiteDomainId),
        TrafficRelatedRulesSubModule(SiteDomainId),
        VehicleAndPPESubModule(SiteDomainId, SafetyAndSecurityModuleId),
        SafetyTestSubModule(SiteDomainId),
        AlarmsAndEmergencySubModule(SiteDomainId),
        SiteIncidentManagementSubModule(SiteDomainId),
        //
        LoadingPointsModule,
        UnloadingPointsModule,
        createModule({
            imports: [BuildingsModule],
        }),
        DataLocalisationModule,
    ],
    providers: [SelectedSiteFilterService, { token: TranslationPrefixToken, useValue: "domains.sites" }],
});
