import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0659c5e6"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-tank" };
const _hoisted_2 = {
    key: 0,
    class: "column-description mb-0"
};
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col-6 column-header" };
const _hoisted_6 = { class: "col-6 column-description" };
const _hoisted_7 = { class: "row" };
const _hoisted_8 = { class: "col-6 column-header" };
const _hoisted_9 = { class: "col-6 column-description" };
const _hoisted_10 = { class: "row" };
const _hoisted_11 = { class: "col-6 column-header" };
const _hoisted_12 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_13 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_14 = { class: "row" };
const _hoisted_15 = { class: "col-6 column-header" };
const _hoisted_16 = { class: "col-6 column-description" };
const _hoisted_17 = { class: "row" };
const _hoisted_18 = { class: "col-6 column-header" };
const _hoisted_19 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_20 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_21 = { class: "row" };
const _hoisted_22 = { class: "col-6 column-header" };
const _hoisted_23 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_24 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_25 = { class: "row" };
const _hoisted_26 = { class: "col-6 column-header" };
const _hoisted_27 = { class: "col-6 column-description" };
const _hoisted_28 = { class: "row" };
const _hoisted_29 = { class: "col-6 column-header" };
const _hoisted_30 = { class: "col-6 column-description" };
const _hoisted_31 = { class: "row" };
const _hoisted_32 = { class: "col-6 column-header" };
const _hoisted_33 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LocalisedField = _resolveComponent("LocalisedField");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.noSpecialRequirements)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.translate("no-special-requirements")), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate("equipment-operated")), 1),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.isRequired(_ctx.model?.equipmentOperatedFromTheGround)), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translate("number-of-compartments")), 1),
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.getValue(_ctx.model?.numberOfCompartments, "NumberOfCompartments")), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.translate("tank-material")), 1),
                    (_ctx.model?.tankMaterials?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.tankMaterials, (tag, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: tag + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(tag, "TankMaterial")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_13, "-"))
                ]),
                _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.translate("manhole")), 1),
                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.getValue(_ctx.model?.manhole, "Manhole")), 1)
                ]),
                _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.translate("insulation")), 1),
                    (_ctx.model?.insulations?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.insulations, (tag, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: tag + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(tag, "Insulation")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_20, "-"))
                ]),
                _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.translate("dome-valve-gasket-material")), 1),
                    (_ctx.model?.domeValveGasketMaterials?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.domeValveGasketMaterials, (tag, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: tag + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(tag, "DomeValveGasketMaterial")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_24, "-"))
                ]),
                _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.translate("dip-tube")), 1),
                    _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.getValue(_ctx.model?.dipTube, "DipTube")), 1)
                ]),
                _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.translate("heated-dip-tube")), 1),
                    _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.getValue(_ctx.model?.heatedDipTube, "HeatedDipTube")), 1)
                ]),
                _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.translate("nitrogen")), 1),
                    _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.isRequired(_ctx.model?.nitrogenUnloading)), 1)
                ]),
                _createVNode(_component_LocalisedField, {
                    modelValue: _ctx.model?.additionalRequirements
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("additional-tank-requirements")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"])
            ]))
    ]));
}
