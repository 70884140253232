import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-58e36e76"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-vehicle" };
const _hoisted_2 = {
    key: 0,
    class: "column-value mb-0"
};
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "column-header col-6" };
const _hoisted_5 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_6 = {
    key: 1,
    class: "col-6 column-value"
};
const _hoisted_7 = { class: "row" };
const _hoisted_8 = { class: "column-header col-6" };
const _hoisted_9 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_10 = {
    key: 1,
    class: "col-6 column-value"
};
const _hoisted_11 = { class: "row" };
const _hoisted_12 = { class: "column-header col-6" };
const _hoisted_13 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_14 = {
    key: 1,
    class: "col-6 column-value"
};
const _hoisted_15 = { class: "row" };
const _hoisted_16 = { class: "col-6 column-header" };
const _hoisted_17 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_18 = {
    key: 1,
    class: "col-6 column-value"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LocalisedField = _resolveComponent("LocalisedField");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.noSpecialRequirements)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.translate("no-special-requirements")), 1))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("vehicle-type-required")), 1),
                    (_ctx.model?.vehicleTypeRequired?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.vehicleTypeRequired, (tag, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: tag + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(tag, "VehicleType")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_6, "-"))
                ]),
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translate("vehicle-type-excluded")), 1),
                    (_ctx.model?.vehicleTypeExcluded?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.vehicleTypeExcluded, (tag, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: tag + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(tag, "VehicleType")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_10, "-"))
                ]),
                _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.translate("vehicle-safety-equipment")), 1),
                    (_ctx.model?.vehicleSafetyEquipment?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.vehicleSafetyEquipment, (tag, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: tag + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(tag, "VehicleSafetyEquipment")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_14, "-"))
                ]),
                _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.translate("chassi")), 1),
                    (_ctx.model?.chassis?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.chassis, (tag, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: tag + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(tag, "Chassis")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_18, "-"))
                ]),
                _createVNode(_component_LocalisedField, {
                    modelValue: _ctx.model?.additionalRequirements
                }, {
                    header: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("additional-vehicle-requirements")), 1)
                    ]),
                    _: 1
                }, 8, ["modelValue"])
            ], 64))
    ]));
}
